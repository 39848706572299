import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section, Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

import imgB1 from "../../assets/jane/eye_bg.png";

const BlogList = ({data}) => (
  <>
    {/* <!-- Blog section --> */}
    <Section bg='#C3E9D8' className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
        {data.allPrismicBlogPost.nodes.map((item, index) => {
                    return (
                      <Col lg="4" className="mb-5">
                      <PostCard
                        img={item.data.hero_image.url}
                        preTitle={item.first_publication_date}
                        title={item.data.title.text}
                        url={item.uid}
                        readMore
                      >
                      </PostCard>
                    </Col>
          
                    )
        })}
          
        </Row>
      </Container>
    </Section>
  </>
);

export default BlogList;
