import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import styled from 'styled-components'
import SEO from "../components/seo";

import BlogList from "../sections/blog/BlogList";


const BlogRegular = ({data}) => {
  return (
    <>
      <PageWrapper >
      <SEO title="Blog" description="The perfect place for you to find the best ways to reconnect with your inner self and take control of your life. Find balance once again."/>
        <Section bg='#C3E9D8' className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title color='white' variant="hero">Jane's Blog</Title>
                <br/>
              </Col>
            </Row>
          </Container>
        </Section>
        <BlogList data={data}/>
      </PageWrapper>
    </>
  );
};


export const query = graphql`
{
  allPrismicBlogPost {
    nodes {
      id
      uid
      lang
      type
      url
      first_publication_date(formatString: "DD MMMM YYYY")

      data{
        title{
          text
        }
        hero_image{
          url
          alt
        }
      }
    }
  }
}

`

export default BlogRegular;
